
.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-modal-content {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 85vw;
}

.image-modal-close {
  position: absolute;
  top: -30px;
  right: -30px;
  font-size: 24px;
  color: var(--dark-orange);
  font-weight: bold;
  padding: 4px;
  border-radius: 10%;
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
}

.image-modal-image {
  max-width: 100%;
  max-height: 80vh;
  display: block;
  margin: 0 auto;
}

.image-modal-arrows {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin-top: 10px; */
}

.image-modal-arrow-left,
.image-modal-arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 45px;
  color: var(--dark-orange);
  cursor: pointer;
  z-index: 10;
}

.image-modal-arrow-left {
  left: -40px;
  padding: 4px;
  border-radius: 10%;
  background-color: rgba(255, 255, 255, 0.9);
}

.image-modal-arrow-right {
  right: -40px;
  padding: 4px;
  border-radius: 10%;
  background-color: rgba(255, 255, 255, 0.9);
}

.more-info-btn {
  position: relative;
  color: var(--white);
  padding: 6px;
  border-radius: 15px;
  background-color: var(--dark-orange);
  text-align: center;
}

.more-info-btn:hover {
  background-color: var(--orange);
}
@media (max-width: 768px) {
  .image-modal-content {
    padding: 0;
    margin: 0;
  }
  .image-modal-content {
    max-width: 95vw;
  }

  .image-modal-arrow-left,
  .image-modal-arrow-right {
    position: static;
    font-size: 35px;
    margin: 2rem 20px;
    /* width: 3rem; */
  }

  .image-modal-arrows {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    /* width: 100%; */
  }

  .image-modal-close {
    top: -100px;
    right: 20px;
    font-size: 20px;
  }
}