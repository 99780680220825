.resume-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: fit-content;
  padding: 50px 0;
}

.resume-content {
  max-width: 1100px;
  width: 90%;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0px 20px -2px #1f2235;
  padding: 30px;
}

.resume-nav {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.resume-nav-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 25px;
  border: none;
  background-color: #ddd;
  color: #666;
  font-family: "Poppins SemiBold";
  cursor: pointer;
  transition: all 0.3s ease;
}

.resume-nav-button.active {
  background-color: var(--dark-orange);
  color: white;
}

.resume-nav-button svg {
  margin-right: 8px;
}

.timeline-container {
  margin-top: 30px;
}

.timeline-item {
  position: relative;
  padding-left: 30px;
  margin-bottom: 30px;
  border-left: 2px solid #ddd;
}

.timeline-item::before {
  content: "";
  position: absolute;
  left: -6px;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--dark-orange);
}

.timeline-header {
  margin-bottom: 10px;
}

.timeline-title {
  font-family: "Poppins Bold";
  color: var(--main-dark-blue);
  font-size: 1.25rem;
  margin-bottom: 5px;
}

.timeline-company {
  color: var(--dark-orange);
  font-family: "Poppins SemiBold";
  margin-bottom: 5px;
}

.timeline-details {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.timeline-description {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.timeline-description li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  color: #444;
}

.timeline-description li::before {
  content: "•";
  margin-right: 8px;
}

@media only screen and (max-width: 768px) {
  .resume-content {
    padding: 20px;
  }

  .resume-nav {
    flex-direction: column;
    gap: 10px;
  }

  .timeline-item {
    padding-left: 20px;
  }
}