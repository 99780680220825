.cards-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	align-content: center;
	width: 100%;
	height: 100%;
	font-size: 16px;
	letter-spacing: 0.2vh;
	font-family: "Poppins SemiBold";
}

.projects-content {
	max-width: 1100px;
	width: 90%;
}

.img {
	max-width: 20em;
	display: block;
	margin: 0 auto;
}

.cards {
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: auto;
	align-self: auto;
	border-radius: 10px;
	box-shadow: 0 0px 20px -2px #1f2235;
	width: 540px;
	height: auto;
	padding: 1rem;
	margin: 1rem;
	background-image: url("https://res.cloudinary.com/dhqh98spd/image/upload/v1702114331/bg-two_mibuvi.webp");
}
.cards > .image > img {
	cursor: zoom-in;
	position: relative;
  }

.image {
	margin: 0 auto;
	width: 90%;
	height: 100%;

}
.img-withoutSlides {
	border-radius: 10px 10px 10px 10px;
	width: 100%;
	height: 100%;
	object-fit: cover;

}

.image>.small-infos {
	color: #ffcab5;
	opacity: 0.7;
	font: 14px "CourBd";
	padding: 0;
	margin: 0;
	text-transform: uppercase;
}

.text {
	padding-top: 3rem;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 2rem;
}

.title {
	padding: 1rem 0;
	color: rgba(255, 255, 255, 0.8);
	text-shadow: 0 10px 10px rgba(255, 255, 255, 0.155);
	text-align: center;
	text-transform: uppercase;
	font-family: "Overpass";
	font-size: 1.8rem;
}

.paragraph {
	padding: 1rem;
	margin: 0;
	/* height: 220px; */
}

.OC-subject {
	color: #ab96f3;
	text-transform: uppercase;
	font-family: "RobotoCondensed-Bold";
	letter-spacing: normal;
	font-size: 0.9rem;
}

.subject {
	color: #c1c1c1;
	text-transform: uppercase;
	font-family: "RobotoCondensed-Bold";
	letter-spacing: normal;
	font-size: 0.9rem;
}

.note-img {
	font-size: 0.8rem;
	color: wheat;
	opacity: 0.5;
}
.bolder {
	font-size: 18px;
	font-family: "Poppins Bold" !important;
	font-weight: bolder;
	color: #a43d1c;
}

.italic-semiBold {
	font-family: "Poppins Medium";
	font-style: italic;
	color: #a43d1c;
	/* font-weight: 600; */
}

.list {
	margin: 0;
	padding: 0 2rem;
}

.list>li {
	padding: 5px;
}


.link-demo {
	color: var(--grey);
	font-family: "RobotoCondensed-Bold";
	text-decoration: underline;
	text-underline-offset: 3px;
	text-decoration-thickness: 5px;
	text-transform: uppercase;
	font-size: 1rem;
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
	margin-top: 10px;
	padding: 7px;
}


.link-demo:hover {
	text-decoration-color: var(--dark-orange);
	text-underline-offset: 3px;
	text-decoration-thickness: 5px;
}

.link-techs {
	display: flex;
	justify-content: flex-end;
	align-content: flex-end;
	padding-top: 1.5rem;
}

.link-techs>a {
	padding: 1ex;
	color: var(--lightest-grey);
	background-color: var(--dark-orange);
	box-shadow: 1px var(--dark-orange);

	font-family: "Hubot-Sans";
	letter-spacing: 2.5px;
	text-decoration: none;
	cursor: pointer;
	border: 1px solid var(--orange);
	border-radius: 12px;
	font-size: 13px;
	/* text-transform: uppercase; */
}

.link-paragraph {
	text-decoration: none;
	cursor: pointer;
	font-family: "Poppins Regular";
	font-style: italic;
	color: #0e282f;
}

.link-techs>a:hover {
	transform: scale(1.1);
}
.tech-list_projectCards {
    text-align: center;
    background: url("https://res.cloudinary.com/dhqh98spd/image/upload/v1702114331/bg-body_tojzok.svg");
    border-radius: 15px;
    padding: 5px;
	margin-top:3rem;
    /* margin: 10px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.tech-list_projectCards img {
	margin: 10px 8px;
	/* width: 50px; */
	height: 40px;
	z-index: 1000;
	opacity: 0.9;
}
@media screen and (max-width: 1024px) {
	.cards {
		width: 400px;
	}
}

@media screen and (max-width: 530px) {

	.download-file,
	.link-demo {
		text-align: center;
		line-height: 30px;
		text-underline-offset: 2px;
		text-decoration-thickness: 3px;
	}
}

@media screen and (max-width: 330px) {

	.cards-container p,
	.cards-container li {
		font-size: 15px;
	}
	.bolder {
		font-size: 0.8rem;
	}
	.car

	.link-techs {
		padding-top: 2rem;
	}

	.link-techs>a {
		font-size: 12px;
		padding: 6px;
	}

	.download-file,
	.link-demo {
		font-size: 14px;
		line-height: 30px;
	}

	.collapse {
		font-size: 10px;
	}
}

.text .more-info {
	margin-top: 1rem;
	padding: 0.7rem 1.5rem;
	background-color: var(--main-dark-blue);
	color: var(--white);
	border: 2px solid var(--white);
	border-radius: 25px;
	font-family: "RobotoCondensed-Bold";
	font-size: 1rem;
	cursor: pointer;
	transition: all 0.3s ease;
	text-transform: uppercase;
	letter-spacing: 1px;
}


.text .more-info:hover {
	background-color: var(--dark-orange);
	border-color: var(--dark-orange);
	transform: translateY(-2px);
	box-shadow: 0 5px 15px rgba(255, 88, 35, 0.3);
}

.text .more-info:active {
	transform: translateY(0);
}