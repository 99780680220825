/* ProjectModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    width: 80vw;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .modal-close {
    position: absolute;
    top:3px;
    right: 20px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;

    font-size: 2rem;
    cursor: pointer;
  }
  
  .modal-close:hover {
    color: #ccc;
  }
  .container_modal {
    width: 100%;
    /* max-height: 95vh; */
    /* overflow-y: auto; */
    padding: 1rem;
    background: url("https://res.cloudinary.com/dhqh98spd/image/upload/v1702114331/bg-two_mibuvi.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .container_modal h1 {
    text-align: center;
    color: rgba(255, 255, 255, 0.855);
    text-shadow: 0 10px 10px rgba(255, 255, 255, 0.155);
    text-transform: uppercase;
    font-family: "Overpass";
    font-size: 1.8rem;
  }
  
  .content_modal {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  
  .image_modal {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .image_modal img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
  }
  
  .slider-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 45px;
    color: var(--dark-orange);
    cursor: pointer;
    z-index: 10;
  }
  
  .slider-arrow.left {
    left: 10px;
  }
  
  .slider-arrow.right {
    right: 10px;
  }
  
  .loupe {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .loupe svg {
    width: 24px;
    height: 24px;
    fill: var(--dark-orange);
  }
  
  .loupe:hover {
    background: rgba(0, 0, 0, 0.8);
    fill: #fff;
    scale: 1.2;
  }
  .description_modal {
    font-family: "Poppins SemiBold";
    line-height: 1.5rem;
    width: 50%;
    max-height: 70vh;
    /* height: 45vh; */
    overflow-y: scroll;
    padding: 20px;
    background: #fff;
    margin-left: 20px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .description_modal::-webkit-scrollbar {
    width: 2rem;
  }
  .description_modal::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .description_modal::-webkit-scrollbar-thumb {
    background: #888;
  }
  .description_modal::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .tech-list {
    text-align: center;
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
    padding: 5px;
    margin: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .tech-list img {
    margin: 6px 20px;
    /* width: 50px; */
    height: 60px;
    z-index: 1000;
    opacity: 0.9;
  }
  
  .download-file {
    display: inline-block;
    margin-top: 1rem;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fafafa;
    opacity: 1;
    font-family: "RobotoCondensed-Bold";
    font-size: 1.2rem;
    text-transform: uppercase;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    width: 60%;
    letter-spacing: 1px;
    background: #1f2235;
    /* color: white; */
    text-decoration: none;
    border-radius: 5px;
  }
  
  .download-file:hover {
    background: #292c40;
    color: white;
    text-decoration-color: var(--dark-orange);
    text-underline-offset: 3px;
    text-decoration-thickness: 5px;
  }
  
  .bolder {
    font-weight: bold;
  }
  
  .italic-semiBold {
    font-style: italic;
    font-weight: 600;
  }
  
  .list {
    margin-top: 10px;
    padding-left: 20px;
  }

@media (max-width: 768px) {
  .description_modal {
    font-size: 1rem;
    width: 98%;
  }

	.bolder {
		font-size: 1rem;
	}
  .download-file {
    font-size: 0.8rem;
    padding: 2px 0;
    width: 100%;

  }
  .content_modal {
    flex-direction: column;
    align-items: center;
  }

  .image_modal, .description_modal {
    width: 100%;
    margin: 10px 0;
  }

  .description_modal {
    margin-left: 0;
  }

  .slider-arrow {
    font-size: 35px;
  }
  .slider-arrow.left {
    left: 5px;
  }

  .slider-arrow.right {
    right: 5px;
  }

  .modal-content {
    width: 100vw;
    height: 85%;
    overflow-y: scroll;
  }
  .tech-list {
    text-align: center;
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
    padding: 5px;
    margin: 4px;
    width: 100%;
    height: fit-content;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .tech-list img {
    margin: 6px 10px;
    /* width: 50px; */
    height: 3rem;
    /* width: 2rem; */
    z-index: 1000;
    opacity: 0.9;
  }
}