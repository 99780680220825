

/* .box{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
} */
.box {
	display: flex;
	justify-content: center;
	margin-top: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.box span{
	display: block;
	width: 20px;
	height: 20px;
	border-bottom: 3px solid var(--dark-orange);
	border-right: 3px solid var(--dark-orange);
	transform: rotate(45deg);
	margin: -10px;
	animation: animate 2s infinite;
}

.box span:nth-child(2)
{
	animation-delay: -0.2s;
}

.box span:nth-child(3)
{
	animation-delay: -0.4s;
}

@keyframes animate{
	
	0%{
		opacity: 0;
		transform: rotate(45deg) translate(-20px, -20px);
	}
	35%{
		opacity: 0.5;
	}
	50%{
		opacity: 0.65;
	}
	75%{
		opacity: 1;
	}
	100%{
		opacity: 0;
		transform: rotate(45deg) translate(20px, 20px);
	}
}