.about-me-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0 0;
}

.about-me-content {
  max-width: 1100px;
  width: 90%;
  box-shadow: 0 0px 20px -2px #1f2235;
  background-color: #f0f0f0;
  border-radius: 12px;
  padding: 30px;
}

.about-me-description {
  font-family: "Poppins Regular";
  font-size: 16px;
  color: var(--black);
  line-height: 1.6;
  margin-bottom: 30px;
  text-align: justify;
}

.tabs-container {
  display: flex;
  border-bottom: 2px solid var(--grey);
  margin-bottom: 30px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  background: none;
  font-family: "Poppins SemiBold";
  font-size: 16px;
  cursor: pointer;
  color: var(--grey);
  transition: all 0.3s ease;
}

.tab-button.active {
  color: var(--dark-orange);
  border-bottom: 2px solid var(--dark-orange);
  margin-bottom: -2px;
}

.tab-button:hover {
  color: var(--dark-orange);
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.skill-category {
  background-color: var(--white);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-title {
  font-family: "Poppins Bold";
  color: var(--main-dark-blue);
  margin-bottom: 15px;
  font-size: 18px;
}

.skill-item {
  display: flex;
  align-items: center;
  margin: 8px 0;
  font-family: "Poppins Regular";
  color: var(--black);
}

.skill-icon {
  color: var(--dark-orange);
  margin-right: 10px;
}

.interests-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.interest-card {
  display: flex;
  align-items: flex-start;
  background-color: var(--white);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.interest-icon {
  color: var(--dark-orange);
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 88, 35, 0.1);
  padding: 8px;
  border-radius: 8px;
}

.interest-content h3 {
  font-family: "Poppins SemiBold";
  color: var(--main-dark-blue);
  margin-bottom: 5px;
  font-size: 16px;
}

.interest-content p {
  color: var(--black);
  font-size: 14px;
  font-family: "Poppins Regular";
}

/* Media Queries */
@media screen and (max-width: 1110px) {
  .about-me-content {
    width: 88%;
  }
}

@media screen and (max-width: 898px) {
  .skills-grid,
  .interests-grid {
    grid-template-columns: 1fr;
  }

  .about-me-content {
    padding: 20px;
  }

  .tab-button {
    font-size: 14px;
    padding: 8px 15px;
  }
}

@media screen and (max-width: 466px) {
  .about-me-description {
    font-size: 14px;
  }

  .category-title {
    font-size: 16px;
  }

  .skill-item {
    font-size: 14px;
  }

  .interest-content h3 {
    font-size: 14px;
  }

  .interest-content p {
    font-size: 12px;
  }
}