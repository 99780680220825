.textShadow {
  text-shadow: 1px 1px 6px var(--dark-orange), 0 0 2em var(--orange);
  background-color: hsl(0,0%,0%, 0.2);
  border-radius: 10px;
  padding: 3px;
}
.zoom-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  background: var(--black);
  border-radius: 50%;
  border: 1px solid var(--orange);
  padding: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.zoom-icon svg {
  width: 100%;
  height: 100%;
  fill: var(--orange);
  /* fill: black; */
}
.zoom-icon:hover {
  transform: scale(1.5);
}